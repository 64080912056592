<template>
  <div id="adoptenme-layout">
    <h1>asdak</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {}
  }
}
</script>
