<template>
  <div id="adoptenme">
    <adoptenme-layout></adoptenme-layout>
  </div>
</template>

<script>
import AdoptenmeLayout from '../layout/AdoptenmeLayout.vue'

export default {
  name: 'Adoptenme',
  components: {
    AdoptenmeLayout
  }
}
</script>
